/** @jsxImportSource @emotion/react */
import { Button, Input } from "antd";
import { DataType } from "../data";
import { buttonStyles } from "../styles";
import { LeftOutlined, DownOutlined, RightOutlined, CloseOutlined } from "@ant-design/icons";
import ActionColumn from "../components/ActionColumn";

export const getRxColumns = (
  handleInputChange: any,
  handleRowUpdate: any,
  handleRowDelete: any,
  isDefaultRow: (key: string) => boolean,
  showActions: boolean
): any => {
  const columns = [
    {
      title: "",
      dataIndex: "rowLabel",
      key: "rowLabel",
      rowSpan: 2,
      className: "custom-table-cell",
    },
    {
      title: "Left Eye",
      className: "left-eye-column",
      children: [
        {
          title: "Sph",
          dataIndex: "leftSph",
          key: "leftSph",
          render: (text: string, record: DataType) => (
            <Input value={text} onChange={(e) => handleInputChange(e, record.key, "leftSph")} />
          ),
        },
        {
          title: "Cyl",
          dataIndex: "leftCyl",
          key: "leftCyl",
          render: (text: string, record: DataType) => (
            <Input value={text} onChange={(e) => handleInputChange(e, record.key, "leftCyl")} />
          ),
        },
        {
          title: "Axis",
          dataIndex: "leftAxis",
          key: "leftAxis",
          render: (text: string, record: DataType) => (
            <Input value={text} onChange={(e) => handleInputChange(e, record.key, "leftAxis")} />
          ),
        },
        {
          title: "Add",
          dataIndex: "leftAdd",
          key: "leftAdd",
          render: (text: string, record: DataType) => (
            <Input value={text} onChange={(e) => handleInputChange(e, record.key, "leftAdd")} />
          ),
        },
        {
          title: "Inter",
          dataIndex: "leftInter",
          key: "leftInter",
          render: (text: string, record: DataType) => (
            <Input value={text} onChange={(e) => handleInputChange(e, record.key, "leftInter")} />
          ),
        },
        {
          title: "VA",
          dataIndex: "leftVa",
          key: "leftVa",
          render: (text: string, record: DataType) => (
            <Input value={text} onChange={(e) => handleInputChange(e, record.key, "leftVa")} />
          ),
        },
      ],
    },
    showActions
      ? {
          title: "",
          key: "actions",
          className: "custom-table-cell",
          render: (_: any, record: DataType) => (
            <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
              <Button
                icon={<LeftOutlined />}
                css={buttonStyles}
                size="small"
                type="primary"
                onClick={() => handleRowUpdate(record.key, "prev")}
              />
              <Button
                icon={<DownOutlined />}
                css={buttonStyles}
                size="small"
                type="primary"
                onClick={() => handleRowUpdate(record.key, "down")}
              />
              <Button
                icon={<RightOutlined />}
                css={buttonStyles}
                size="small"
                type="primary"
                onClick={() => handleRowUpdate(record.key, "next")}
              />

              {!isDefaultRow(record.key) && (
                <Button
                  icon={<CloseOutlined />}
                  css={buttonStyles}
                  size="small"
                  danger
                  type="primary"
                  onClick={() => handleRowDelete(record.key)}
                />
              )}
            </div>
          ),
        }
      : {
          title: "",
          key: "separator",
          className: "custom-table-cell",
          width: 100,
          render: () => (
            <div
              style={{
                height: "100%",
                borderLeft: "1px solid #d9d9d9",
              }}
            />
          ),
        },
    {
      title: "Right Eye",
      className: "right-eye-column",
      children: [
        {
          title: "Sph",
          dataIndex: "rightSph",
          key: "rightSph",
          render: (text: string, record: DataType) => (
            <Input value={text} onChange={(e) => handleInputChange(e, record.key, "rightSph")} />
          ),
        },
        {
          title: "Cyl",
          dataIndex: "rightCyl",
          key: "rightCyl",
          render: (text: string, record: DataType) => (
            <Input value={text} onChange={(e) => handleInputChange(e, record.key, "rightCyl")} />
          ),
        },
        {
          title: "Axis",
          dataIndex: "rightAxis",
          key: "rightAxis",
          render: (text: string, record: DataType) => (
            <Input value={text} onChange={(e) => handleInputChange(e, record.key, "rightAxis")} />
          ),
        },
        {
          title: "Add",
          dataIndex: "rightAdd",
          key: "rightAdd",
          render: (text: string, record: DataType) => (
            <Input value={text} onChange={(e) => handleInputChange(e, record.key, "rightAdd")} />
          ),
        },
        {
          title: "Inter",
          dataIndex: "rightInter",
          key: "rightInter",
          render: (text: string, record: DataType) => (
            <Input value={text} onChange={(e) => handleInputChange(e, record.key, "rightInter")} />
          ),
        },
        {
          title: "VA",
          dataIndex: "rightVa",
          key: "rightVa",
          render: (text: string, record: DataType) => (
            <Input value={text} onChange={(e) => handleInputChange(e, record.key, "rightVa")} />
          ),
        },
      ],
    },
    {
      title: "",
      key: "actions-right",
      className: "custom-table-cell",
      render: (_: any, record: DataType) => <ActionColumn showActions={showActions} />,
    },
  ];
  return columns.filter(Boolean);
};
