import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import Breadcrumbs from "../BreadCrumbs";
import { useBreadcrumbs } from "hooks/useBreadcrumbs";

const Container = styled.div`
  padding: 12px;
`;

type BreadcrumbItem = {
  href: string;
  label: string;
  icon?: React.ReactNode;
};

type Props = {
  children: ReactNode;
  currentNode?: string;
  additionalBreadcrumbs?: BreadcrumbItem[];
  showBreadcrumbs?: boolean;
};

const PageTemplate = ({
  children,
  currentNode,
  additionalBreadcrumbs,
  showBreadcrumbs = true,
}: Props) => {
  const additionalItems = additionalBreadcrumbs?.map((item) => ({
    href: item.href,
    label: item.label,
    icon: item.icon,
  }));

  const breadcrumbItems = useBreadcrumbs({
    currentNode,
    additionalItems,
  });

  return (
    <Container>
      <div>{children}</div>
    </Container>
  );
};

export default PageTemplate;
