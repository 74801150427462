import NotFound from "components/PageNotFound";
import { SubscriptionForm, SubscriptionPage } from "Scenes/Subscription";
import { PatientRecord, PatientRecordForm } from "Scenes/PatientRecords";
import { AppointmentPage } from "Scenes/Appointment";
import ListTicketTypes from "Scenes/Support/components/SupportTicketType/ListTicketTypes";
import { HelpAndSupport } from "Scenes/Support";
import { ConsultationPage } from "Scenes/Consultation";
import { ResetPasswordPage } from "Scenes/PasswordRecovery";
import { ChangePasswordPage } from "Scenes/PasswordRecovery";
import { Dashboard } from "Scenes/Dashboard";
import { LayoutPage } from "Scenes/Layout";
import { LoginPage } from "Scenes/Auth/Login";
import ConsultationDetailsPage from "Scenes/Consultation/components/ConsultationDetails";
import SubscriptionCard from "Scenes/Subscription/components/SubscriptionCard";
import { SundryDetailsPage, SundryListPage, SundryForm } from "Scenes/Inventory/Sundry";
import { FrameListPage, FrameForm, FrameDetailsPage } from "Scenes/Inventory/Frame";
import { SupplierListPage, SupplierDetailsPage, SupplierForm } from "Scenes/MasterSetup/Supplier";
import { PaymentTypeList } from "Scenes/MasterSetup/PaymentType";
import { FrameGroupList } from "Scenes/MasterSetup/FrameGroup";
import { UserList } from "Scenes/UserManagement";

export const RouteComponents = {
  LoginPage,
  ResetPasswordPage,
  ChangePasswordPage,
  Dashboard,
  AppointmentPage,
  PatientRecord,
  PatientRecordForm,
  SubscriptionForm,
  ConsultationPage,
  HelpAndSupport,
  NotFound,
  LayoutPage,
  SubscriptionPage,
  ListTicketTypes,
  ConsultationDetailsPage,
  SubscriptionCard,
  SundryListPage,
  SundryDetailsPage,
  SundryForm,
  FrameListPage,
  SupplierListPage,
  SupplierDetailsPage,
  SupplierForm,
  FrameForm,
  FrameDetailsPage,
  PaymentTypeList,
  FrameGroupList,
  UserList,
};
