export const QUERY_KEYS = {
  SUBSCRIPTION_PLANS: "subscription-plans",
  TICKET_TYPES: "Ticket-types",
  SUPPORT_TICEKTS: "Support-Tickets",
  USER_EMAILS: "user-emails",
  PATIENT_RECORDS: "patient_records",
  APPOINTMENT: "appointment",
  SUNDRY: "sundry",
  FRAME: "frame",
  SUPPLIER: "supplier",
  PAYMENT_TYPE: "paymenttype",
  FRAME_GROUP: "framegroup",
  USER_MANAGEMENT: "usermanagement",
};
