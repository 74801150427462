import React from "react";
import { Col, Form, Input, Checkbox, Row, Space, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routeConstants";
import { SubmitButton, ForgotPasswordLink } from "../styles";

interface LoginFormProps {
  form: any;
  onFinish: () => void;
  isSubmitting: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ form, onFinish, isSubmitting }) => {
  const navigate = useNavigate();

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item
        label="Enter email"
        name="email"
        rules={[{ required: true, message: "Email is required" }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Enter email" size="middle" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Password is required" }]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Password" size="middle" />
      </Form.Item>

      <Row justify="space-between" style={{ marginBottom: "20px" }}>
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Keep me logged in</Checkbox>
        </Form.Item>
        <ForgotPasswordLink onClick={() => navigate(ROUTES.AUTH.RESET_PASSWORD)}>
          Forgot your password?
        </ForgotPasswordLink>
      </Row>

      <Form.Item>
        <SubmitButton  type="primary" size="middle" loading={isSubmitting} onClick={()=>navigate("/dashboard")}>
          Login
        </SubmitButton>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
