import React from "react";
import { Card, Divider, Descriptions, Table } from "antd";

const boldLabelStyle = { fontWeight: "bold", color: "#141414" };

const FrameDetailsPage: React.FC = () => {
  // Dummy frame details data
  const frameDetails = {
    frameCode: "F12345",
    brand: "Brand XYZ",
    displayStock: 20,
    model: "Model A1",
    colorCode: "C5678",
    frameStatus: "Active",
    visionBarCode: "VZ12345",
    location: "Store 2, Aisle 5",
    frameSize: "Medium",
    temple: "135mm",
  };

  // Dummy sales and purchase details
  const salesPurchaseDetails = {
    returnBy: "2024-12-31", // or a Date object
    lastSale: "2024-10-01", // or a Date object
    firstPurchase: "2023-01-15", // or a Date object
    lastPurchase: "2024-09-25", // or a Date object
    lastInvoiceNo: "INV-123456",
    availableFrom: "2024-10-05", // or a Date object
    availableTill: "2024-11-05", // or a Date object
  };

  // Dummy cost details
  const costDetails = {
    costPrice: "$50",
    taxRate: "12%",
    discount: "10%",
  };

  // Dummy data for the cost details table
  const costTableData = [
    { key: "1", priceFor: "Current List Price", excludingTax: "$50", includingTax: "$56" },
    { key: "2", priceFor: "Current Cost Price", excludingTax: "$45", includingTax: "$50.4" },
    { key: "3", priceFor: "Previous Cost Price", excludingTax: "$40", includingTax: "$44.8" },
    { key: "4", priceFor: "Average Cost Price", excludingTax: "$42", includingTax: "$47" },
    { key: "5", priceFor: "Retail Price", excludingTax: "$60", includingTax: "$67.2" },
  ];

  // Columns for the cost details table
  const costTableColumns = [
    {
      title: "Prices For",
      dataIndex: "priceFor",
      key: "priceFor",
      width: "33%",
    },
    {
      title: "Excluding Tax",
      dataIndex: "excludingTax",
      key: "excludingTax",
      width: "33%",
    },
    {
      title: "Including Tax",
      dataIndex: "includingTax",
      key: "includingTax",
      width: "33%",
    },
  ];

  const supplierDetails = {
    supplier: "Supplier ABC",
    supplierBarCode: "John Doe",
    supplierStockCode: "+1 234 567 890",
  };

  const reorderDetails = {
    reOrderRequired: "yes", // or "no"
    reorderWhenLessThan: 20,
    reorderQty: 50,
    reorderNote: "Please reorder before stock runs out.",
  };

  return (
    <Card
      style={{
        padding: "30px 30px",
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      {/* Frame Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
        Frame Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Frame Code</span>}>
          {frameDetails.frameCode}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Brand</span>}>
          {frameDetails.brand}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Display Stock</span>}>
          {frameDetails.displayStock}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Model</span>}>
          {frameDetails.model}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Color Code</span>}>
          {frameDetails.colorCode}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Frame Status</span>}>
          {frameDetails.frameStatus}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Vision Bar Code</span>}>
          {frameDetails.visionBarCode}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Location</span>}>
          {frameDetails.location}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Frame Size</span>}>
          {frameDetails.frameSize}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Temple</span>}>
          {frameDetails.temple}
        </Descriptions.Item>
      </Descriptions>

      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Supplier Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Supplier</span>}>
          {supplierDetails.supplier}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Supplier Bar Code</span>}>
          {supplierDetails.supplierBarCode}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Supplier Stock Code</span>}>
          {supplierDetails.supplierStockCode}
        </Descriptions.Item>
      </Descriptions>

      {/* Re-Order Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Re-Order Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Re-Order Required</span>}>
          {reorderDetails.reOrderRequired}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Re-Order When Less Than</span>}>
          {reorderDetails.reorderWhenLessThan}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Re-Order Quantity</span>}>
          {reorderDetails.reorderQty}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Re-Order Note</span>}>
          {reorderDetails.reorderNote}
        </Descriptions.Item>
      </Descriptions>
      {/* Cost Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Cost Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Cost Price</span>}>
          {costDetails.costPrice}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Tax Rate</span>}>
          {costDetails.taxRate}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Discount</span>}>
          {costDetails.discount}
        </Descriptions.Item>
      </Descriptions>

      {/* Cost Details Table */}
      <Table
        columns={costTableColumns}
        dataSource={costTableData}
        pagination={false}
        bordered={true}
        style={{ marginTop: "34px" }}
      />

      {/* Sales and Purchase Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Sales and Purchase Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Return By</span>}>
          {salesPurchaseDetails.returnBy}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Last Sale</span>}>
          {salesPurchaseDetails.lastSale}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>First Purchase</span>}>
          {salesPurchaseDetails.firstPurchase}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Last Purchase</span>}>
          {salesPurchaseDetails.lastPurchase}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Last Invoice No</span>}>
          {salesPurchaseDetails.lastInvoiceNo}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Available From</span>}>
          {salesPurchaseDetails.availableFrom}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Available Till</span>}>
          {salesPurchaseDetails.availableTill}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default FrameDetailsPage;
