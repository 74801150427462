export const BASE_PATHS = {
  DASHBOARD: "/dashboard",
  APPOINTMENT: "/appointment",
  HELP_SUPPORT: "/help-support",
  TICKET_TYPES: "/ticket-types",
};

export const AUTH_ROUTES = {
  LOGIN: "/signin",
  REGISTER: "/signup",
  RESET_PASSWORD: "/resetpassword",
  CHANGE_PASSWORD: "/changepassword",
};

export const PATIENT_RECORDS_ROUTES = {
  BASE: "/patient-records",
  ADD_UPDATE: "/patient-records/add/update",
  DETAIL: (id: string) => `/patient-records/${id}`,
};

export const SUBSCRIPTION_ROUTES = {
  BASE: "/subscription",
  CREATE: "/subscription/create",
  UPDATE: "/subscription/update/:id",
  DETAILS: "/subscription/details/:id",
};

export const USER_ROUTES = {
  BASE: "/user-management",
  CREATE: "/user-management/create",
  UPDATE: "/user-management/update/:id",
  DETAILS: "/user-management/details/:id",
};

export const SUNDRY_ROUTES = {
  BASE: "/inventory/sundry",
  CREATE: "/inventory/sundry/create",
  UPDATE: "/inventory/sundry/update/:id",
  DETAILS: "/inventory/sundry/details/:id",
};

export const SUPPLIER_ROUTES = {
  BASE: "/master-setup/supplier",
  CREATE: "/master-setup/supplier/create",
  UPDATE: "/master-setup/supplier/update/:id",
  DETAILS: "/master-setup/supplier/details/:id",
};

export const FRAME_ROUTES = {
  BASE: "/inventory/frame",
  CREATE: "/inventory/frame/create",
  UPDATE: "/inventory/frame/update/:id",
  DETAILS: "/inventory/frame/details/:id",
};

export const PAYMENT_TYPE_ROUTES = {
  BASE: "/master-setup/payment-type",
  CREATE: "/master-setup/payment-type/create",
  UPDATE: "/master-setup/payment-type/update/:id",
  DETAILS: "/master-setup/payment-type/details/:id",
};

export const FRAME_GROUP_ROUTES = {
  BASE: "/master-setup/frame-group",
  CREATE: "/master-setup/frame-group/create",
  UPDATE: "/master-setup/frame-group/update/:id",
  DETAILS: "/master-setup/frame-group/details/:id",
};

export const CONSULTATION_ROUTES = {
  BASE: "/consultation",
  DETAIL: (id: string) => `/consultation/${id}`,
};

export const Fallback_ROUTES = {
  NOT_FOUND: "*",
};

export const ROUTES = {
  AUTH: AUTH_ROUTES,
  DASHBOARD: BASE_PATHS.DASHBOARD,
  APPOINTMENT: BASE_PATHS.APPOINTMENT,
  PATIENT_RECORDS: PATIENT_RECORDS_ROUTES,
  SUBSCRIPTION: SUBSCRIPTION_ROUTES,
  CONSULTATION: CONSULTATION_ROUTES,
  HELP_SUPPORT: BASE_PATHS.HELP_SUPPORT,
  NOT_FOUND: Fallback_ROUTES.NOT_FOUND,
  TICKET_TYPES: BASE_PATHS.TICKET_TYPES,
  SUNDRY: SUNDRY_ROUTES,
  FRAME: FRAME_ROUTES,
  SUPPLIER: SUPPLIER_ROUTES,
  PAYMENT_TYPE: PAYMENT_TYPE_ROUTES,
  FRAME_GROUP: FRAME_GROUP_ROUTES,
  USER: USER_ROUTES,
};
