import React from "react";
import { Card, Divider, Descriptions } from "antd";

const boldLabelStyle = { fontWeight: "bold", color: "#141414" };

const SupplierDetailsPage: React.FC = () => {
  // Dummy supplier details data matching the fields in the SupplierForm
  const supplierDetails = {
    supplierName: "Supplier XYZ",
    supplierCode: "SUP-123",
    alias: "XYZ Corp",
    abn: "123456789",
    contactName: "John Doe",
    province: "New York",
    district: "Manhattan",
    municipality: "Central District",
    street: "133 Supplier Lane",
    phone: "+133456789",
    email: "supplier@xyz.com",
    fax: "+123456789",
    eOrder: "Yes",
    eOrderKey: "ORDKEY123",
    eOrderPath: "/orders",
    accountNo: "ACC123456",
    eyetalkName: "Eyetalk XYZ",
    defaultFittingType: "Standard",
    labFile: "labfile.pdf",
    notes: "Preferred supplier for optical lenses.",
  };

  return (
    <Card
      style={{
        padding: "30px 30px",
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      {/* Supplier Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
        Supplier Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Supplier Name</span>}>
          {supplierDetails.supplierName}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Supplier Code</span>}>
          {supplierDetails.supplierCode}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Alias</span>}>
          {supplierDetails.alias}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>ABN</span>}>
          {supplierDetails.abn}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Contact Name</span>}>
          {supplierDetails.contactName}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Province</span>}>
          {supplierDetails.province}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>District</span>}>
          {supplierDetails.district}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Municipality</span>}>
          {supplierDetails.municipality}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Street/Tole</span>}>
          {supplierDetails.street}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Phone No.</span>}>
          {supplierDetails.phone}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Email</span>}>
          {supplierDetails.email}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Fax No.</span>}>
          {supplierDetails.fax}
        </Descriptions.Item>
      </Descriptions>

      {/* Electronic Order Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Electronic Order Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>E. Order</span>}>
          {supplierDetails.eOrder}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>E. Order Key</span>}>
          {supplierDetails.eOrderKey}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>E. Order Path</span>}>
          {supplierDetails.eOrderPath}
        </Descriptions.Item>
      </Descriptions>

      {/* Other Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Other Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Account No.</span>}>
          {supplierDetails.accountNo}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Eyetalk Name</span>}>
          {supplierDetails.eyetalkName}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Default Fitting Type</span>}>
          {supplierDetails.defaultFittingType}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Lab File</span>}>
          {supplierDetails.labFile}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Notes</span>}>
          {supplierDetails.notes}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default SupplierDetailsPage;
