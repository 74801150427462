import React from "react";
import { Menu } from "antd";
import {
  SquaresFour,
  Stethoscope,
  Invoice,
  TipJar,
  Calendar,
  Warehouse,
  GearSix,
  Users,
  Question,
} from "@phosphor-icons/react";

interface MenuProps {
  onSelect: (e: any) => void;
  selectedKeys: string;
  openKeys: string[];
  onOpenChange: (e: string[]) => void;
}

const iconColor = (isSelected: boolean) => (isSelected ? "#1499B8" : "#4B5563");

const AppMenu: React.FC<MenuProps> = ({ onSelect, selectedKeys, openKeys, onOpenChange }) => {
  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      selectedKeys={[selectedKeys]}
      onClick={onSelect}
      style={{ borderRight: 0 }}
    >
      <Menu.Item
        key="/dashboard"
        icon={
          <SquaresFour size={18} color={iconColor(selectedKeys === "/dashboard")} weight="fill" />
        }
      >
        Dashboard
      </Menu.Item>
      <Menu.Item
        key="/consultation"
        icon={
          <Stethoscope
            size={18}
            color={iconColor(selectedKeys === "/consultation")}
            weight="fill"
          />
        }
      >
        Consultation
      </Menu.Item>
      <Menu.Item
        key="/billing-details"
        icon={
          <Invoice size={18} color={iconColor(selectedKeys === "/billing-details")} weight="fill" />
        }
      >
        Billing Details
      </Menu.Item>
      <Menu.Item
        key="/subscription"
        icon={
          <TipJar size={18} color={iconColor(selectedKeys === "/subscription")} weight="fill" />
        }
      >
        Subscription
      </Menu.Item>
      <Menu.Item
        key="/appointment"
        icon={
          <Calendar size={18} color={iconColor(selectedKeys === "/appointment")} weight="fill" />
        }
      >
        Appointments
      </Menu.Item>
      <Menu.Item
        key="/user-management"
        icon={
          <Users size={18} color={iconColor(selectedKeys === "/user-management")} weight="fill" />
        }
      >
        User Management
      </Menu.Item>
      <Menu.SubMenu
        key="/master-setup"
        icon={
          <GearSix
            size={18}
            color={iconColor(selectedKeys.includes("/master-setup"))}
            weight="fill"
          />
        }
        title="Master Setup"
      >
        <Menu.Item key="/master-setup/lens-code-spectacle">Lens Code Spectacle</Menu.Item>
        <Menu.Item key="/master-setup/supplier">Supplier Setup</Menu.Item>
        <Menu.Item key="/master-setup/payment-type">Payment Type</Menu.Item>
        <Menu.Item key="/master-setup/frame-group">Frame Group</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="/inventory"
        icon={
          <Warehouse
            size={18}
            color={iconColor(selectedKeys.includes("/inventory"))}
            weight="fill"
          />
        }
        title="Inventory"
      >
        <Menu.Item key="/inventory/frame">Frame Details</Menu.Item>
        <Menu.Item key="/inventory/sundry">Sundry Details</Menu.Item>
      </Menu.SubMenu>
      {/* New Help and Support Item */}
      <Menu.Item
        key="/help-support"
        icon={
          <Question size={18} color={iconColor(selectedKeys === "/help-support")} weight="fill" />
        }
      >
        Help and Support
      </Menu.Item>
    </Menu>
  );
};

export default AppMenu;
