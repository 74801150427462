import React from "react";
import { Button, Row, Switch, Table, Menu, Dropdown, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ROUTES } from "constants/routeConstants";
import PageTemplate from "components/PageTemplate";
import { useNavigate } from "react-router-dom";
import { SubscriptionPackageInterface, useSubscriptionService } from "services/SubscriptionService";
import useConfirmDelete from "hooks/useConfirmDelete";
import { EyeOutlined, EditOutlined, DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { subscriptionPackages } from "../data";
import LoadingCard from "components/LoadingComponent";

const SubscriptionPage: React.FC = () => {
  const navigate = useNavigate();
  const { useGetAll, useDelete } = useSubscriptionService();
  const { data: subscriptionPackages1, isLoading, refetch } = useGetAll();
  const { mutateAsync: deletePlan, isPending } = useDelete();
  const { confirmDelete } = useConfirmDelete(deletePlan, isPending);

  //   if (isLoading) return <LoadingCard />;

  const handleMenuClick = (e: any, record: SubscriptionPackageInterface) => {
    switch (e.key) {
      case "view":
        navigate(`/subscription/details/${record?._id}`);
        break;
      case "edit":
        navigate(`/subscription/update/${record?._id}`);
        break;
      case "delete":
        record?._id && confirmDelete(record?._id);
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      title: "SN",
      dataIndex: "index",
      key: "index",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Subscription",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Monthly Charge",
      dataIndex: "monthlyCost",
      key: "monthlyCost",
      render: (monthlyCost: { cost: number }) => `$${monthlyCost.cost.toFixed(2)}`,
    },
    {
      title: "Annual Charge",
      dataIndex: "annualCost",
      key: "annualCost",
      render: (annualCost: { cost: number }) => `$${annualCost.cost.toFixed(2)}`,
    },
    {
      title: "Free Trial Duration",
      dataIndex: "trial",
      key: "trial",
      render: (trial: { days: number }) => (trial.days ? `${trial.days} days` : "No Trial"),
    },
    {
      title: "Status",
      dataIndex: "purchaseDisable",
      key: "purchaseDisable",
      render: (purchaseDisable: boolean) => <Switch checked={!purchaseDisable} />,
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: SubscriptionPackageInterface) => {
        const menu = (
          <Menu onClick={(e) => handleMenuClick(e, record)}>
            <Menu.Item key="view" icon={<EyeOutlined />}>
              View Details
            </Menu.Item>
            <Menu.Item key="edit" icon={<EditOutlined />}>
              Edit Details
            </Menu.Item>
            <Menu.Item key="delete" icon={<DeleteOutlined />} danger>
              Delete Package
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const dataSource = subscriptionPackages.map((item, index) => ({
    ...item,
    key: index,
  }));

  return (
    <PageTemplate>
      <Row justify="end">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => navigate(ROUTES.SUBSCRIPTION.CREATE)}
        >
          Create New Plan
        </Button>
      </Row>

      <div style={{ marginTop: "18px" }}>
        <Table columns={columns} dataSource={dataSource} />
      </div>
    </PageTemplate>
  );
};

export default SubscriptionPage;
