import PageTemplate from "components/PageTemplate";
import { ROUTES } from "constants/routeConstants";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Dropdown, Menu, Row } from "antd";
import {
  MoreOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useSundryService } from "services/SundryService/useSundryService";
import useConfirmDelete from "hooks/useConfirmDelete";

const SundryListPage = () => {
  const navigate = useNavigate();
  const { useGetAll, useDelete } = useSundryService();
  //   const { data: sundryData, isLoading, refetch } = useGetAll();
  const { mutateAsync: deletePlan, isPending } = useDelete();
  const { confirmDelete } = useConfirmDelete(deletePlan, isPending);

  const data = [
    {
      key: "1",
      sundryCode: "SND001",
      sundryName: "Item 1",
      brand: "Brand A",
      supplier: "Supplier X",
      sundryGroup: "Group 1",
      continue: "Yes",
      stock: 50,
    },
    {
      key: "2",
      sundryCode: "SND002",
      sundryName: "Item 2",
      brand: "Brand B",
      supplier: "Supplier Y",
      sundryGroup: "Group 2",
      continue: "No",
      stock: 20,
    },
  ];

  const handleMenuClick = (key: string, record: any) => {
    switch (key) {
      case "view":
        navigate(`/inventory/sundry/details/${record?.key}`);
        break;
      case "update":
        navigate(`/inventory/sundry/update/${record?.key}`);
        break;
      case "delete":
        record?.key && confirmDelete(record?.key);
        break;
      default:
        break;
    }
  };

  const actionMenu = (record: any) => (
    <Menu>
      <Menu.Item key="view" icon={<EyeOutlined />} onClick={() => handleMenuClick("view", record)}>
        View details
      </Menu.Item>
      <Menu.Item
        key="update"
        icon={<EditOutlined />}
        onClick={() => handleMenuClick("update", record)}
      >
        Update sundry
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        onClick={() => handleMenuClick("delete", record)}
        danger
      >
        Delete sundry
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Sundry Code",
      dataIndex: "sundryCode",
    },
    {
      title: "Sundry Name",
      dataIndex: "sundryName",
    },
    {
      title: "Brand",
      dataIndex: "brand",
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
    },
    {
      title: "Sundry Group",
      dataIndex: "sundryGroup",
    },
    {
      title: "Continue",
      dataIndex: "continue",
    },
    {
      title: "Stock",
      dataIndex: "stock",
    },
    {
      title: "Action",
      render: (text: any, record: any) => (
        <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <PageTemplate>
      <Row justify="end">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => navigate(ROUTES.SUNDRY.CREATE)}
        >
          Add Sundry
        </Button>
      </Row>

      <div style={{ marginTop: "18px" }}>
        <Table columns={columns} dataSource={data} />
      </div>
    </PageTemplate>
  );
};

export default SundryListPage;
