import { ROUTES } from "constants/routeConstants";

export const getParentRoute = (pathname: string): string | undefined => {
  const baseRoutes = [
    ROUTES.DASHBOARD,
    ROUTES.APPOINTMENT,
    ROUTES.PATIENT_RECORDS.BASE,
    ROUTES.SUBSCRIPTION.BASE,
    ROUTES.CONSULTATION.BASE,
    ROUTES.HELP_SUPPORT,
    ROUTES.TICKET_TYPES,
    ROUTES.SUNDRY.BASE,
    ROUTES.FRAME.BASE,
    ROUTES.PAYMENT_TYPE.BASE,
    ROUTES.FRAME_GROUP.BASE,
    ROUTES.SUPPLIER.BASE,
    ROUTES.USER.BASE,
  ];

  const parentRoute = baseRoutes.find((route) => pathname.startsWith(route));

  return parentRoute || ROUTES.NOT_FOUND;
};
