export const routeTitles: Record<string, string> = {
  "/dashboard": "Dashboard",
  "/consultation": "Consultation",
  "/consultation/add": "Add Consultation",
  "/consultation/edit": "Edit Consultation",
  "/consultation/details": "Consultation Details",
  "/billing-details": "Billing Details",
  "/subscription": "Subscription",
  "/subscription/create": "Add Subscription",
  "/subscription/update": "Update Subscription",
  "/subscription/details": "Subscription Details",
  "/appointment": "Appointments",
  "/appointment/add": "Add Appointment",
  "/appointment/update": "Update Appointment",
  "/appointment/details": "Appointment Details",
  "/master-setup": "Master Setup",
  "/inventory/frame/create": "Add Frame",
  "/inventory/frame/details": "Frame Details",
  "/inventory/frame/update": "Update Frame",
  "/inventory/sundry/create": "Add Sundry",
  "/inventory/sundry/details": "Sundry Details",
  "/inventory/sundry/update": "Update Sundry",
  "/master-setup/payment-type": "Payment Type",
  "/master-setup/supplier/create": "Add Supplier",
  "/master-setup/supplier/details": "Supplier Details",
  "/master-setup/supplier/update": "Update Supplier",
  "/user-management": "User Management",
};
